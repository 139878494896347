<template>
    <div>
        <v-dialog
            v-model="crop_is_show"
            persistent
            max-width="800px"
        >
            <v-card

            >
                <v-card-title>
                    <span class="text-h5">Ritaglia immagione</span>
                </v-card-title>
                <v-card-text>
                    <div class="content">
                        <section class="cropper-area">
                            <div class="img-cropper">
                                <vue-cropper
                                    ref="cropper"
                                    :src="cropperContent"
                                    alt="Source Image"
                                    :aspect-ratio="1"
                                >
                                </vue-cropper>
                            </div>
                        </section>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click.prevent="closeCropper"
                    >
                        Chiudi
                    </v-btn>
                    <v-btn
                        @click.prevent="cropperReset"
                    >
                        Reset
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click.prevent="zoom(0.2)"
                    >
                        Zoom In
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click.prevent="zoom(-0.2)"
                    >
                        Zoom Out
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click.prevent="cropImage"
                    >
                        Seleziona
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="is_show"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Upload File</span>
                </v-card-title>
                <v-card-text>
                    <vue-dropzone
                        ref="myVueDropzone"
                        id="dropzone"
                        :options="dropzoneOptions"
                        @vdropzone-file-added="(file) => passToCropper(file)"
                        @vdropzone-complete="reloadAndClose"
                    ></vue-dropzone>
                    <v-col cols="12" class="text-right">
                        <v-btn
                            @click="closeModal"
                            class="ma-2"
                            :disabled="loading"
                        >
                            Chiudi
                        </v-btn>
                        <v-btn
                            @click="launchUpload"
                            class="ma-2"
                            color="primary"
                            :loading="loading"
                        >
                            Carica
                        </v-btn>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Compressor from 'compressorjs';

export default {
    name: "upload-avatar",
    components: {
        vueDropzone: vue2Dropzone,
        vueCropper: VueCropper
    },
    props: {
        is_show: {
            type: Boolean,
            default: false
        },
        upload_url : String,
    },
    data() {
        return {
            dropzoneOptions: {
                dictDefaultMessage: 'Trascina il file oppure clicca per caricare',
                url: this.upload_url,
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                maxFilesize: 2,
                autoProcessQueue: false,
                headers: { "Authorization": "Bearer "+this.$store.state.auth.token },
                paramName : "images"
            },
            cropped : false,
            crop_is_show : false,
            cropperContent : "",
            fileName : "",
            loading: false,
            compressedFile: {}
        }
    },
    methods: {
        async reloadAndClose() {
            this.loading = false
            this.$emit('reload-data')
            await this.closeModal()
        },
        closeModal() {
            this.$emit('close')
            this.loading = false
            this.cropperReset()
            this.$refs.myVueDropzone.removeAllFiles()
        },
        closeCropper() {
            this.cropperReset()
            this.crop_is_show = false
            this.cropperContent = ""
        },
        passToCropper(file) {

            if (this.$refs.myVueDropzone.dropzone.files[1]!=null){
                this.removeFile(this.$refs.myVueDropzone.dropzone.files[0])
            }

            if (!this.cropped) {
                this.$refs.myVueDropzone.removeFile(file)

                this.cropperContent = this.getSrcImageFromBlob(file)
                this.fileName = file.name
                this.crop_is_show = true
            } else {
                this.cropped = false
                file.previewElement.querySelector('img').src = URL.createObjectURL(file)
            }
        },
        getSrcImageFromBlob(blob) {
            var urlCreator = window.URL || window.webkitURL
            return urlCreator.createObjectURL(blob)
        },
        blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date()
            theBlob.name = fileName
            return theBlob
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent)
        },
        cropperReset() {
            this.$refs.cropper.reset();
        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.$refs.cropper.getCroppedCanvas().toBlob((blob)=> {
                var croppedFile = this.blobToFile(blob, this.fileName)
                croppedFile.accepted = true
                this.cropped = true
                this.crop_is_show = false

                new Compressor(croppedFile, {
                    mimeType: 'image/jpeg',
                    quality: 0.6,
                    maxWidth: 250,
                    minWidth: 250,
                    success: (compressedResult) => {
                        this.setCompressedImage(compressedResult)
                        this.cropperReset()
                    },
                    error: (err) =>  {
                        console.log(err.message);
                    },
                })
            });
        },
        setCompressedImage(compressedImage){
            this.$refs.myVueDropzone.dropzone.addFile(compressedImage)
        },
        async launchUpload() {
            this.loading = true
            await this.$refs.myVueDropzone.processQueue()
            this.$refs.myVueDropzone.removeAllFiles()
        }
    },
}
</script>

<style scoped>

</style>