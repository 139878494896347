<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="text-h5">
              Il mio profilo
            </v-card-title>

            <v-card-subtitle>
              Puoi modificare o inserire i dati del tuo profilo
            </v-card-subtitle>

            <v-card-text>
              <v-form
                ref="formProfile"
                @submit.prevent="update"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                  >
                    <v-avatar
                      color="grey lighten-2"
                      size="100"
                      @click="uploadAvatarIsShow=true"
                    >
                      <v-img
                        v-if="loggedUserAvatar"
                        :src="loggedUserAvatar"
                      />
                      <span
                        v-else
                        class="text-h5"
                      >
                        {{ loggedUser.initials }}
                      </span>
                    </v-avatar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                  >
                    <v-text-field
                      v-model="loggedUser.name"
                      label="Nome"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUser.surname"
                      label="Cognome"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-if="loggedUser.role === 'user'"
                      v-model="loggedUser.cf"
                      label="Codice Fiscale"
                      outlined
                      required
                      dense
                      :rules="[formRules.required,formRules.cfRule]"
                    ></v-text-field>
                    <v-text-field
                      v-if="loggedUser.role === 'professional'"
                      v-model="loggedUser.piva"
                      label="Partita Iva"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUser.email"
                      label="Email"
                      outlined
                      required
                      dense
                      disabled
                      :rules="[formRules.required,formRules.emailRules]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUser.phone1"
                      label="Telefono 1"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUser.phone2"
                      label="Telefono 2"
                      outlined
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions
              class="justify-end mx-2 hidden-xs-only"
            >
              <v-btn
                color="primary"
                @click.prevent="update"
              >
                Salva
              </v-btn>
            </v-card-actions>
            <v-card-actions
              class="justify-end mx-2 hidden-sm-and-up"
            >
              <v-btn
                color="primary"
                block
                @click.prevent="update"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="text-h5">
              Il miei consensi
            </v-card-title>

            <v-card-subtitle>
              Puoi modificare i tuoi consensi
            </v-card-subtitle>

            <v-card-text>
              <v-form
                ref="formPreferences"
                @submit.prevent="updatePreferences"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                  >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                  >
                    <v-checkbox
                      v-model="loggedUserPreferences.privacy_setting"
                      dense
                      label="Acconsento al Trattamento dei Dati Personali"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="loggedUserPreferences.marketing_setting"
                      dense
                      label="Acconsento al Marketing"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions
              class="justify-end mx-2 hidden-xs-only"
            >
              <v-btn
                color="primary"
                @click.prevent="updatePreferences"
              >
                Salva
              </v-btn>
            </v-card-actions>
            <v-card-actions
              class="justify-end mx-2 hidden-sm-and-up"
            >
              <v-btn
                color="primary"
                block
                @click.prevent="updatePreferences"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <upload-avatar
      :upload_url="uploadUrl"
      :is_show="uploadAvatarIsShow"
      @close="uploadAvatarIsShow=false"
      @reload-data="reloadData"
    ></upload-avatar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadAvatar from '@/components/modals/upload-avatar.vue'

export default {
  name: 'Profile',
  components: { UploadAvatar },
  computed: {
    ...mapGetters(['formRules', 'loggedUser', 'loggedUserAvatar', 'loggedUserPreferences']),
  },
  data() {
    return {
      uploadAvatarIsShow: false,
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Il mio profilo',
          to: '/profile',
        },
      ],
      uploadUrl: `${this.$apiUrl}profile/avatar`,
    }
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async update() {
      if (this.$refs.formProfile.validate()) {
        try {
          await this.$api.updateProfile(this.loggedUser)
          await this.$store.dispatch('set_alert', { msg: 'Profilo aggiornato', type: 'success' })
          await this.$store.dispatch('getUserInfo')
        } catch (e) {
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    async updatePreferences() {
      try {
        await this.$api.updateProfilePreferences(this.loggedUserPreferences)
        await this.$store.dispatch('set_alert', { msg: 'Profilo aggiornato', type: 'success' })
        await this.$store.dispatch('getUserPreferences')
      } catch (e) {
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async reloadData() {
      await this.$store.dispatch('getUserAvatar', this.loggedUser.id)
    },
  },
}
</script>
